import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "C:/Users/felix/OneDrive/Documents/Bertram.Solutions/Website.TTcom/GatsbyJS-2022/src/components/mdx/component.js";
import { GenericPage } from "../../../components/mdx/page";
import { Breadcrumbs } from "../../../components/widgets/breadcrumbs";
import { Aside } from "../../../components/widgets/aside";
import { Border } from "../../../components/widgets/container";
import { ButtonPrimary, ButtonSecondary } from "../../../components/widgets/button";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = GenericPage;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">





    <h1>{`How To Rebalance Your Accounts`}</h1>
    <Breadcrumbs to="/help/" text="Help" mdxType="Breadcrumbs" />
    <h2>{`Overview`}</h2>
    <Aside mdxType="Aside">
      <p><strong parentName="p">{`See Also`}</strong></p>
      <ul>
        <li parentName="ul"><a parentName="li" {...{
            "href": "/help/investment-dashboard/"
          }}>{`Managing your investments`}</a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "/help/habits-for-success/"
          }}>{`Habits for success`}</a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "/help/managed-accounts/"
          }}>{`Managed accounts`}</a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "/help/api/"
          }}>{`API access`}</a></li>
      </ul>
    </Aside>
    <p>{`Tactical portfolios adapt their asset allocation to the markets to take advantage of profitable market opportunities and avoid deep drawdowns. The asset allocation at any given time is determined by an `}<a parentName="p" {...{
        "href": "https://en.wikipedia.org/wiki/Algorithm"
      }}>{`algorithm`}</a>{`, which is a set of pre-defined rules coded in software. Every time the portfolio's algorithm calculates a new target allocation for the portfolio, you need to adjust the holdings in your accounts to match this allocation. This process is called `}<em parentName="p">{`rebalancing`}</em>{`.`}</p>
    <div css={`height:0;clear:both;`} />
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "952px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/f24d7e8e6e02a517d0bf39fe2d03d362/7e4a6/accounts-dashboard.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "14.333333333333334%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAADCAYAAACTWi8uAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAApUlEQVQI1z2NUWrDMBBEff8btNfIXw/Qr56ggX4ECsWWI8m7Wu3KhhdiaD6GmTcMzLRtwh6Gm+K9Eb2hImxVzvyUqTLPmVrrq+tNkWwcHngY4UZ0ZVrSQi6ZCMe9n64qpDW9uDWllMwYQf/fmHBdvvmrv0QpdBXcnWlOifx8HgOPOF1UWdYVj3FyM2O9Z3wMegT7OEgt8X594+Pnwv75hd1u+HHwANwn5d1/4TwOAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "TuringTrader.com: accounts on the dashboard",
            "title": "TuringTrader.com: accounts on the dashboard",
            "src": "/static/f24d7e8e6e02a517d0bf39fe2d03d362/7e4a6/accounts-dashboard.png",
            "srcSet": ["/static/f24d7e8e6e02a517d0bf39fe2d03d362/5a46d/accounts-dashboard.png 300w", "/static/f24d7e8e6e02a517d0bf39fe2d03d362/0a47e/accounts-dashboard.png 600w", "/static/f24d7e8e6e02a517d0bf39fe2d03d362/7e4a6/accounts-dashboard.png 952w"],
            "sizes": "(max-width: 952px) 100vw, 952px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <h2>{`When to Rebalance`}</h2>
    <p>{`Our portfolios have a regular rebalancing schedule. Some portfolios only rebalance monthly, some weekly, and others daily. `}<em parentName="p">{`TuringTrader.com`}</em>{` can send you email notifications when it is time to rebalance your portfolio. Also, your dashboard will indicate which accounts require your attention, simply watch out for the green `}<em parentName="p">{`rebalance`}</em>{` buttons.`}</p>
    <p>{`At `}<em parentName="p">{`TuringTrader.com`}</em>{`, we understand that life is busy. Therefore, all of our portfolios rebalance outside of regular market hours. The timing looks roughly like this (see more details `}<a parentName="p" {...{
        "href": "/help/status/"
      }}>{`here`}</a>{`):`}</p>
    <ul>
      <li parentName="ul">{`4 pm (New York, USA): U.S. stock exchanges close`}</li>
      <li parentName="ul">{`9 pm (New York, USA): New target allocations available`}</li>
      <li parentName="ul">{`Time to prepare rebalancing`}</li>
      <li parentName="ul">{`9:30 am (New York, USA): Rebalancing orders fill`}</li>
    </ul>
    <p>{`As you can see, you have a wide window to perform the necessary steps, and there is no rush to be at your desk at a specific time of the day.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "987px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/8d9809125b497d6163986e14b8a39ef3/d1d24/account-info.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "17%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAADCAYAAACTWi8uAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAAnElEQVQI12WMywrCMBRE+/8/IvgNIq6UanEhigWhCxdpEttqTWKe7UgC7aaLw9xhuCcTUiEipUR3uaItzuDHAiw/gR9yKMpgQ4ANA/w4wsXbuYSxds6JTCkFyjgI42j7Hsb79DgRuw0eza1EtduDVE+Q1wfqpxeyJBRCglCKmjKQmqLr3mnQxsy4YQDZbPFYrdHcS3y1htZ6IYvCP0os5HNK3qL7AAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "TuringTrader.com: account information",
            "title": "TuringTrader.com: account information",
            "src": "/static/8d9809125b497d6163986e14b8a39ef3/d1d24/account-info.png",
            "srcSet": ["/static/8d9809125b497d6163986e14b8a39ef3/5a46d/account-info.png 300w", "/static/8d9809125b497d6163986e14b8a39ef3/0a47e/account-info.png 600w", "/static/8d9809125b497d6163986e14b8a39ef3/d1d24/account-info.png 987w"],
            "sizes": "(max-width: 987px) 100vw, 987px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <h2>{`Rebalancing steps`}</h2>
    <h3>{`Preparations`}</h3>
    <p>{`Before starting the rebalancing procedure, it is a good idea to double-check some information. In particular:`}</p>
    <ul>
      <li parentName="ul">{`The `}<strong parentName="li">{`account status`}</strong>{` timestamp gives you the date and time we last updated your portfolio. For convenience, the time is shown in your local time zone. The last update should be just a few hours old unless it's the weekend. If it is older than that, the site might not have been updated yet.`}</li>
      <li parentName="ul">{`The `}<strong parentName="li">{`last scheduled rebalancing`}</strong>{` timestamp shows you when this portfolio last adjusted its holdings. This date should be either today or yesterday, depending on the time of the day. If it is older, there is likely nothing to do for this portfolio today.`}</li>
      <li parentName="ul">{`The `}<strong parentName="li">{`approximated account value`}</strong>{` should match the net liquidation value of your brokerage account. We are trying our best to reflect the market's ups and downs in this number. However, you will still need to adjust it once in a while. You can do so after clicking the little pencil icon.`}</li>
    </ul>
    <p>{`It is a good idea to set an account value slightly lower than the net liquidation value shown by your brokerage. Doing so will help accommodate fees and commissions and ensure your order size is valid, even when the market prices change overnight.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "986px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/0fb3bce6d1bfa7c82aecfe5e685e1d85/0a867/account-holdings.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "29.666666666666668%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAGCAYAAADDl76dAAAACXBIWXMAAA7EAAAOxAGVKw4bAAABFklEQVQY002P227DIBBE/f/fV/WhUaMowcYXCGDucaRTgVupD6PdYWZ3lsE5j1IGv3u0tpQcKSn0Wlvfaok461gWzbxoUgzdb/dAUJr1ciOKkaI1w6odISaMNdzuD4QQiHFEaUXOmVIytRaccyit2TZFSunkxrBfr4wfn8ivC+n7ymB9JNfK7j3zurEqxbJuLNuGNpZ6HJT6wnmPNqYjpITdd2aliMaSxHhe+DQMynhcSH3hJGfksiCmCbmsbPrZB+vrhXWuBzWPD6G/N29sv3i/TxwHw/b07CFjneV2F4xSMsqZmBIp5z7QFhprmaTkIaauNf6Yzj6XQmmolSHls2mp+mm6mH/F/wgxdt1Y13kL+/P+9/8AKo7J3kS894oAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "TuringTrader.com: account information",
            "title": "TuringTrader.com: account information",
            "src": "/static/0fb3bce6d1bfa7c82aecfe5e685e1d85/0a867/account-holdings.png",
            "srcSet": ["/static/0fb3bce6d1bfa7c82aecfe5e685e1d85/5a46d/account-holdings.png 300w", "/static/0fb3bce6d1bfa7c82aecfe5e685e1d85/0a47e/account-holdings.png 600w", "/static/0fb3bce6d1bfa7c82aecfe5e685e1d85/0a867/account-holdings.png 986w"],
            "sizes": "(max-width: 986px) 100vw, 986px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`The asset allocation table summarizes the holdings in your account. The table shows each asset's target allocation, current holdings, and order size. The target allocation is given in percent and shares to hold. This way, you will have the information you need in case your rebalancing tool calculates the number of shares for you. The current allocation is memorized from the last time you rebalanced the account. It is important to remember that `}<em parentName="p">{`TuringTrader.com`}</em>{` does not have access to your brokerage account; therefore, this number might not be perfectly accurate. The order column calculates the difference between the target and current columns. If the target number of shares is higher than the current holdings, a buy order is needed; if it is lower, a sell order will be shown.`}</p>
    <h3>{`Placing Your Orders`}</h3>
    <p>{`Having completed the preparations, you are now ready to place your orders. Doing so is also a multi-step process:`}</p>
    <ul>
      <li parentName="ul">{`liquidate any assets held but not shown in the table`}</li>
      <li parentName="ul">{`place the orders as displayed in the table`}</li>
      <li parentName="ul">{`check for correctness`}</li>
    </ul>
    <p>{`First, we recommend comparing your asset allocation table to the assets held in your account. Then, liquidate any holdings in your account that are not listed in the table with the target allocation. This step is typically only required for portfolios of individual stocks, but it can't hurt to verify.`}</p>
    <p>{`Next, enter your orders one by one. Unless otherwise noted, `}<em parentName="p">{`TuringTrader.com`}</em>{` uses market orders, which are expected to fill at the next open. Make sure to double-check your orders for correctness.`}</p>
    <p>{`We recommend not being too literal when placing your orders to avoid excessive commissions. Many brokerages charge a minimum fee, e.g., $1, per trade. Therefore, your change to your portfolio allocation should be meaningful enough to justify those $1 in commissions. Assuming you might earn 1% on a short-term trade, this profit must be significantly larger than the commission paid. As a rule of thumb, you should probably ignore orders that reallocate less than $500 of capital.`}</p>
    <p>{`Some account types do not allow overlapping trades, with sell orders for one instrument submitted simultaneously with buy orders for another. This is why we recommend using margin accounts for trading, even though our portfolios don't use margin. If you cannot place overlapping orders, we recommend placing the sell orders while the markets are closed, and the buy orders right after the markets open. Trading a few minutes after the markets open will typically not make a noticeable difference. However, the later you put in these orders, the larger the deviation between your actual results and our backtested results may become.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "978px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/ed466847b3bdf2bd828c00f265f11eab/914c7/account-finish.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "37.666666666666664%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAICAYAAAD5nd/tAAAACXBIWXMAAA7EAAAOxAGVKw4bAAABfklEQVQoz42O3arTQBRG+5KC4Dt4KfhuXoiKgleH06KCwaZJZpKZTDJpfuavLUua1sK582IxG74969ubUhgGO5AXDcZ0HIeBo7UM1jKNI8s04ZYZ2w8UpUIIRWt6/DxR1y3G9CzTiNY93js2RT0g9cCvrCTLJVkuyPYV2V6guyN2XBhGhzaWvKwphKJRLeO378hGo7RhcZ55cYQY2EyLJ0RPWQlqpSjF7W1NR9f3LM5xupyZvKNpW2qt0T9+4p931GWFMh0hBHwIhBivQsfiFmTTUDcKpVv6YcCfTjdiJMwzk5R0vzOa5x12u8Pvc9ShwNjhpTBESOmCkC3aHtGmw16FpsPXDT4/ECrBJARNUVAIyfGafflK2/V01q6yh7CQWwqx5U/+xCwrYiVXiW8UvuvX9nC/dnKOaZ7xKa2F1yxe57tsFb799Ip3n9/w/sNr8qePXMIZf0rE8/lGSutijJGUEul0WucXWUoPNr08YOsCKwu8c+viv7YH94//w1+VqVzieoanWgAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "TuringTrader.com: finish rebalancing",
            "title": "TuringTrader.com: finish rebalancing",
            "src": "/static/ed466847b3bdf2bd828c00f265f11eab/914c7/account-finish.png",
            "srcSet": ["/static/ed466847b3bdf2bd828c00f265f11eab/5a46d/account-finish.png 300w", "/static/ed466847b3bdf2bd828c00f265f11eab/0a47e/account-finish.png 600w", "/static/ed466847b3bdf2bd828c00f265f11eab/914c7/account-finish.png 978w"],
            "sizes": "(max-width: 978px) 100vw, 978px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <h3>{`Finish Rebalancing`}</h3>
    <p>{`After placing your orders for this account, click the `}<em parentName="p">{`Finish Rebalancing`}</em>{` button. This step marks your account holdings as current. In particular, this copies the share numbers from the target allocation to the current column, clearing out the order column. On the dashboard, the action button for this account will now read `}<em parentName="p">{`Review`}</em>{` instead of `}<em parentName="p">{`Rebalance`}</em>{`. Also, this turns off the rebalancing notifications until the next time the asset allocation changes.`}</p>
    <h3>{`Checking the Fills`}</h3>
    <p>{`It makes sense to check your order fills at your earliest convenience. Most of the time, things should be just fine. However, occasionally, an asset's price went up significantly since we calculated the order size. In this case, your account's cash balance will be negative. Don't let it sit like that until the next rebalance. Instead, go and sell a few shares to make up for the price increase until your account's cash balance is positive.`}</p>
    <p>{`If you follow these rules, your results from actual trading should closely track our backtested results. However, if you are interested in the details, we recommend reading our `}<a parentName="p" {...{
        "href": "/articles/live-trading-vs-backtests/"
      }}>{`article comparing live trading with backtests`}</a>{`.`}</p>
    <p>{`But successful investing requires more than just following the routine of placing orders. It is essential to make some room in your life to keep the rebalancing schedule. It is vital to stay the course, even when portfolio performance falls short of expectations. This is only possible if you choose a portfolio that fits your objective.`}</p>
    <br />
    <ButtonPrimary text="learn about habits for success" to="/help/habits-for-success/" mdxType="ButtonPrimary" />
    <br /><br /><br />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      